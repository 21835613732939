import PopoverMessageWrapper from '@/common/components/PopoverMessageWrapper';
import { useApplicationContext } from '@/context/ApplicationContext';
import type {} from '@/graphql/types';
import { formatAssetName } from '@/modules/assets/utils';
import WorkOrderStatusLabel from '@/modules/workOrders/components/WorkOrderStatusLabel';
import { useWorkOrderStatus } from '@/modules/workOrders/hooks/useWorkOrderStatus';
import {} from '@/modules/workOrders/types/workOrder';
import {
  formatDateToMDHHmm_or_YYYYMDHHmm,
  formatDateToMD_or_YYYYMD,
  isBeforeDaysFromToday,
  isBeforeToday,
} from '@/utils/date/date';
import useTranslation from '@/utils/i18n/useTranslation';
import { gql } from '@apollo/client';
import {
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { MouseEvent, forwardRef, useMemo } from 'react';
import { FaCheck, FaTasks } from 'react-icons/fa';
import {
  MdAttachment,
  MdCalendarToday,
  MdError,
  MdMoreHoriz,
  MdOutlineModeComment,
  MdSettings,
} from 'react-icons/md';
import type { WorkOrderCard_WorkOrderFragment } from './WorkOrderCard.generated';
import { WorkOrderActionNames, WorkOrderActionsType } from './WorkOrderCardListTabs';
import WorkOrderPriorityLabel from './WorkOrderPriorityLabel';

gql`
fragment WorkOrderCard_WorkOrder on WorkOrder {
  id
  title
  asset {
    id
    name
  }  
  status
  priority
  stoppage {
    startAt
    endAt
  }
  dueDate
  updatedAt
  assignees {
    id
    name
  }
  customFieldFileValues {
    # 本来IDを選択したいが、IWorkOrder > IWorkOrderCustomFieldFileValueのidがoptionalで型が合わないので一旦
    # TODO DisplayWorkOrder削除！！！！
    customFieldId
  }
  parts {
    quantity
  }
  commentCount
  createdAt
  createdBy {
    id
    name
  }
  checkLists {
    id
  }
}
`;

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;
export type WorkOrderCardProps = {
  onWorkOrderClicked: (id: number) => void;
  // 本来不要なOptionalだけど、NotificationEvent等でDisplayWorkOrderを経由で取得しているところがあり埋められない
  // TODO DisplayWorkOrder削除！！！！
  workOrder: Omit<
    Optional<
      WorkOrderCard_WorkOrderFragment,
      | 'asset'
      | 'assignees'
      | 'commentCount'
      | 'createdBy'
      | 'customFieldFileValues'
      | 'parts'
      | 'checkLists'
    >,
    '__typename'
  >;
  isActive?: boolean;
  workOrderActions?: WorkOrderActionsType;
  hasManagePermission?: boolean;
};

type ActionInfoType = {
  label: string;
  message: string;
  hasPermission: boolean;
};

const DueDateDisplay = ({ dueDate, isDone }: { dueDate: Date; isDone: boolean }) => {
  const color = isDone
    ? 'transparent'
    : isBeforeToday(dueDate)
      ? 'error'
      : isBeforeDaysFromToday(dueDate, 7)
        ? 'warning'
        : 'transparent';

  return (
    <Badge colorScheme={color} variant={color === 'transparent' ? 'subtle' : 'solid'}>
      <Flex alignItems='center'>
        <Icon as={MdCalendarToday} fontSize='sm' />
        <Text fontSize='sm' ml={1}>
          {formatDateToMD_or_YYYYMD(dueDate)}
        </Text>
      </Flex>
    </Badge>
  );
};

const WorkOrderCard = forwardRef<HTMLDivElement, WorkOrderCardProps>(
  function WorkOrderCard(props, ref?) {
    const {
      id,
      title,
      asset,
      status,
      priority,
      stoppage,
      dueDate,
      updatedAt,
      assignees,
      customFieldFileValues,
      parts,
      commentCount,
      createdAt,
      createdBy,
      checkLists = [],
    } = props.workOrder;
    const {
      onWorkOrderClicked,
      isActive = false,
      workOrderActions = {},
      hasManagePermission = true,
    } = props;

    const { t } = useTranslation();
    const { getStatusLabel } = useWorkOrderStatus();
    const { me, isAdmin } = useApplicationContext();

    const stoppageStartAt = stoppage && stoppage.startAt;
    const isDone = status === 'done';

    const actionPayload = {
      id,
      status,
    };

    const _workOrderActions = { ...workOrderActions };

    if (isDone && _workOrderActions?.changeStatusDone) {
      delete _workOrderActions.changeStatusDone;
    }

    const workOrderActionsKeys = Object.keys(_workOrderActions) as WorkOrderActionNames[];

    const handleActionClick = (event: MouseEvent<HTMLButtonElement>, _action: string) => {
      event.stopPropagation();

      const action = workOrderActions && workOrderActions[_action as WorkOrderActionNames];
      action && action(actionPayload);
    };

    const hasCheckLists = checkLists.length > 0;

    const isUserWorkOrderOwner = createdBy?.id === me?.id;
    const hasChangeStatusPermission = isAdmin || !isDone;
    const hasDeletePermission = isAdmin || (!isDone && isUserWorkOrderOwner);

    const workOrderActionInfoMap: Record<WorkOrderActionNames, ActionInfoType> = useMemo(() => {
      return {
        changeStatusDone: {
          label: getStatusLabel('done'),
          message: t('warning.no-permission.complete'),
          hasPermission: hasChangeStatusPermission,
        },
        onDelete: {
          label: t('actions.delete'),
          message: t('warning.no-permission.delete'),
          hasPermission: hasDeletePermission,
        },
        removeWorkOrderLink: {
          label: t('actions.remove-task-link'),
          message: '',
          hasPermission: true, // 誰でもリンクを削除できる
        },
      };
    }, [getStatusLabel, hasChangeStatusPermission, hasDeletePermission, t]);

    const checkPermissionByAction = (action: WorkOrderActionNames) => {
      return hasManagePermission && workOrderActionInfoMap[action].hasPermission;
    };

    const isUpdatedAtDisplayed = !stoppageStartAt && !dueDate && !hasCheckLists;

    return (
      <Box
        p={2}
        _hover={{
          bg: isActive ? 'primary.50' : 'neutral.50',
          cursor: 'pointer',
        }}
        bg={isActive ? 'primary.50' : 'transparent'}
        borderWidth={1}
        onClick={() => onWorkOrderClicked(id)}
        ref={isActive ? ref : undefined}
      >
        <Stack align='stretch' textAlign='left'>
          <Flex>
            <Text fontSize='lg' letterSpacing='wide' color='neutral.800'>
              {title}
            </Text>
            <Spacer />
          </Flex>

          <Flex my={1} gap={5} justifyContent='space-between' alignItems='flex-end'>
            <Text color='neutral.800'>{asset && formatAssetName(asset)}</Text>
            <WorkOrderStatusLabel status={status} />
          </Flex>

          <Flex my={1} justifyContent='space-between' flexWrap='wrap' rowGap={3}>
            <Flex alignItems='center' flex='1 1 0%' flexWrap='wrap' columnGap={3}>
              {priority && <WorkOrderPriorityLabel priority={priority} />}
              {stoppageStartAt && (
                <Flex alignItems='center'>
                  <Icon as={MdError} color='neutral.500' fontSize='sm' />
                  <Text color='neutral.500' fontSize='sm' ml={1}>
                    {formatDateToMDHHmm_or_YYYYMDHHmm(stoppageStartAt)}
                  </Text>
                </Flex>
              )}
              {dueDate && <DueDateDisplay dueDate={dueDate} isDone={isDone} />}

              {hasCheckLists && (
                <Flex alignItems='center'>
                  <Icon as={FaTasks} color='neutral.500' fontSize='sm' />
                  <Text color='neutral.500' fontSize='sm' ml={1}>
                    {formatDateToMDHHmm_or_YYYYMDHHmm(createdAt)}
                  </Text>
                </Flex>
              )}

              {isUpdatedAtDisplayed && (
                <Flex alignItems='center'>
                  <Icon as={FaCheck} color='neutral.500' fontSize='sm' />
                  <Text color='neutral.500' fontSize='sm' ml={1}>
                    {formatDateToMD_or_YYYYMD(updatedAt)}
                  </Text>
                </Flex>
              )}

              {customFieldFileValues && customFieldFileValues.length > 0 && (
                <Flex alignItems='center'>
                  <Icon as={MdAttachment} color='neutral.500' fontSize='sm' />
                  <Text color='neutral.500' fontSize='sm' ml={1}>
                    {customFieldFileValues.length}
                  </Text>
                </Flex>
              )}

              {parts && parts.length > 0 && (
                <Flex alignItems='center'>
                  <Icon as={MdSettings} color='neutral.500' fontSize='sm' />
                  <Text color='neutral.500' fontSize='sm' ml={1}>
                    {parts.length}
                  </Text>
                </Flex>
              )}
              {commentCount !== undefined && commentCount > 0 && (
                <Flex alignItems='center'>
                  <Icon as={MdOutlineModeComment} color='neutral.500' fontSize='sm' />
                  <Text color='neutral.500' fontSize='sm' ml={1}>
                    {commentCount}
                  </Text>
                </Flex>
              )}
            </Flex>

            <Flex alignItems='center' columnGap={1}>
              {assignees && assignees.length > 0 && (
                <AvatarGroup size='xs' max={2}>
                  {assignees.map((assignee) => (
                    <Tooltip key={assignee.id} hasArrow label={assignee.name}>
                      <Avatar size='xs' name={assignee.name} />
                    </Tooltip>
                  ))}
                </AvatarGroup>
              )}
              {workOrderActionsKeys.length > 0 && (
                <Menu>
                  <MenuButton
                    as={IconButton}
                    size='xs'
                    variant='ghost'
                    _hover={{ bg: 'white' }}
                    rounded='full'
                    fontSize={24}
                    aria-label='WorkOrder Quick Action'
                    icon={<MdMoreHoriz />}
                    onClick={(e) => e.stopPropagation()}
                  />
                  <MenuList minW='150px'>
                    {workOrderActionsKeys.map((action) => {
                      return (
                        <PopoverMessageWrapper
                          message={workOrderActionInfoMap[action].message}
                          isDisabled={!checkPermissionByAction(action)}
                          key={action}
                        >
                          <MenuItem
                            onClick={(e) => handleActionClick(e, action)}
                            fontSize='sm'
                            fontWeight='normal'
                            isDisabled={!checkPermissionByAction(action)}
                          >
                            {workOrderActionInfoMap[action].label}
                          </MenuItem>
                        </PopoverMessageWrapper>
                      );
                    })}
                  </MenuList>
                </Menu>
              )}
            </Flex>
          </Flex>
        </Stack>
      </Box>
    );
  }
);

export default WorkOrderCard;
